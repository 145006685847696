<template>
  <!-- eslint-disable -->
  
  <div class="mt-4">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      loader="dots"
      color="#7367f0"
    />
    
    
    <b-card>
      <b-row>
        <!-- User Info: Left col -->
        <b-col cols="21" xl="6" class="d-flex flex-column">
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ dataContract ? dataContract.name.toUpperCase() : "" }}
                </h4>
                <span class="card-text">{{
                  dataContract
                    ? dataContract.company.business_name.toUpperCase()
                    : ""
                }}</span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">NIVEL GLOBAL DE CUMPLIMIENTO</h4>
                <b-badge
                  v-if="dataContract"
                  pill
                  :variant="
                    dataContract.completion_percentage < 60
                      ? 'danger'
                      : dataContract.completion_percentage < 85
                      ? 'warning'
                      : 'success'
                  "
                  >{{ dataContract.completion_percentage }}</b-badge
                >
              </div>
            </div>
          </div>
          <b-col cols="12" md="3">
            <b-row>
              <b-col cols="12" class="d-flex justify-content-center">
                <b-button 
                v-on:click= "showComposeEmail"
                  variant="primary"
                  class=""
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"                  
                >
                  Enviar reporte
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-col>

        <!-- Right Col: Table -->
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">Responsable</span>
              </th>
              <td class="pb-50">
                {{
                  dataContract ? dataContract.responsible_sst.toUpperCase() : ""
                }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="UsersIcon" class="mr-75" />
                <span class="font-weight-bold">Nro Trabajadores</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ dataContract ? dataContract.qty_workers : "" }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="GitBranchIcon" class="mr-75" />
                <span class="font-weight-bold">Alcance</span>
              </th>
              <td class="pb-50">
                {{ dataContract ? dataContract.scope.toUpperCase() : "" }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="SettingsIcon" class="mr-75" />
                <span class="font-weight-bold">Desarrollado por</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{
                  dataContract ? dataContract.development_by.toUpperCase() : ""
                }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">Fecha Evaluación</span>
              </th>
              <td class="pb-50">
                {{
                  dataContract
                    ? moment(dataContract.evaluation_date).format("yyyy-MM-DD")
                    : ""
                }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-card-header class="d-flex align-items-center justify-content-center">
        <b-card-title class="font-weight-bolder">
          <span>Grafico de reporte</span>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <vue-apex-charts
          type="bar"
          ref="apexChart"
          height="300"
          :options="options"
          :series="series"
        />
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-table-simple hover responsive class="rounded-bottom mb-0">
        <b-thead head-variant="light">
          <b-tr>
            <b-th colspan="1"> Nro </b-th>
            <b-th colspan="6"> Descripcion </b-th>
            <b-th colspan="2"> % Cumplimiento </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in dataReport">
            <b-td colspan="1">{{ index + 1 }} </b-td>
            <b-td colspan="6" class="text-left">{{ item.description }} </b-td>
            <b-td colspan="2"> {{ item.compliance_percentage }} %</b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-th colspan="1"></b-th>
            <b-th colspan="6">
              NIVEL DE CUMPLIMIENTO DE
              {{ dataContract ? dataContract.name.toUpperCase() : "" }}
            </b-th>
            <b-th colspan="2"
              >{{
                dataContract ? dataContract.completion_percentage : ""
              }}
              %</b-th
            >
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-card>
    <b-card no-body>
      <b-table-simple hover responsive class="rounded-bottom mb-0">
        <b-thead head-variant="light">
          <b-tr>
            <b-th colspan="2"> Titulo </b-th>
            <b-th colspan="1"> Descripcion </b-th>
            <b-th colspan="1"> Ref. Legal </b-th>
            <b-th colspan="1"> Cumplimiento </b-th>
            <b-th colspan="1"> %Cumplimiento </b-th>
            <b-th colspan="7"> Hallazgos </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in contractDetail" :key="index">
            <b-th v-if="item.title" :rowspan="item.childrenLength" colspan="2"
              >{{ item.numberParent }}. {{ item.title }}
            </b-th>
            <b-td colspan="1" class="text-left"
              >{{ item.number }}. {{ item.subtitle }}
            </b-td>
            <b-td colspan="1"> {{ item.ref_legal }} </b-td>
            <b-td colspan="1">
              <span>{{ item.compliance }}</span>
            </b-td>
            <b-td colspan="1">
              <span>{{ item.compliance_percentage }}</span>
            </b-td>
            <b-td colspan="7">
              <span>{{ item.finding }}</span></b-td
            >
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-card no-body>
      <b-table-simple hover responsive class="rounded-bottom mb-0">
        <b-thead head-variant="light">
          <b-tr>
            <b-th colspan="8" class="text-center"> Leyenda </b-th>
          </b-tr>
          <b-tr>
            <b-th colspan="4" class="text-center">
              Criterios de evaluación
            </b-th>
            <b-th colspan="2" class="text-center"> Valoración </b-th>
            <b-th colspan="2" class="text-center"> Hallazgo </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-th colspan="1" class="text-center">
              <span>NO</span>
            </b-th>
            <b-td colspan="3" class="text-center">
              <span
                >Las actividades y métodos demuestran que no se tiene el
                requisito implementando</span
              >
            </b-td>
            <b-th colspan="2" class="text-center">0%</b-th>
            <b-th colspan="2" class="text-center"></b-th>
          </b-tr>
          <b-tr>
            <b-th colspan="1" class="text-center">
              <span>PARCIAL</span>
            </b-th>
            <b-td colspan="3" class="text-center">
              <span
                >Las actividades y métodos demuestran que se tiene el requisito
                parcialmente implementado</span
              >
            </b-td>
            <b-th colspan="2" class="text-center">50%</b-th>
            <b-th colspan="2" class="text-center">Detallar evidencia</b-th>
          </b-tr>
          <b-tr>
            <b-th colspan="1" class="text-center">
              <span>SI</span>
            </b-th>
            <b-td colspan="3" class="text-center">
              <span
                >Las activdiades y métodos demuestran que los requisitos legales
                se encuentran implementados</span
              >
            </b-td>
            <b-th colspan="2" class="text-center">100%</b-th>
            <b-th colspan="2" class="text-center">Detallar evidencia</b-th>
          </b-tr>
          <b-tr>
            <b-th colspan="1" class="text-center">
              <span>N/A</span>
            </b-th>
            <b-td colspan="3" class="text-center">
              <span>No aplica por la realidad de la organización</span>
            </b-td>
            <b-th colspan="2" class="text-center">100%</b-th>
            <b-th colspan="2" class="text-center"
              >Detallar por que no aplica</b-th
            >
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <email-compose v-model="shallShowEmailComposeModal" />
    
  </div>
</template>

<script>
/* eslint-disable */
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ContractService from "@/services/ContractService";
import EmailCompose from '../email/EmailCompose.vue'
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BTableSimple,
  BThead,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BForm,
  BTr,
  BTh,
  BTd,
  BTbody,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
  BTfoot,
  BBadge,
  BModal
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    VueApexCharts,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BForm,
    BFormTextarea,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    BTfoot,
    BBadge,
    Loading,
    EmailCompose
  },
  data() {
    return {
      shallShowEmailComposeModal: false,
      dataReport: [],
      contractDetail: [],
      contractData: {},
      series: [
        {
          data: [],
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: "info",
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "30%",
            endingShape: "rounded",
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          max: 100,
          categories: [],
        },
        yaxis: {
          // opposite: isRtl,
        },
      },
      
      form: {
          id: null,
          email: '',
          content: '',
      },
      show: true
    };
  },

  beforeMount(){
    
  },

  mounted() {
    
    this.getData();
  },
  methods: {

    
    showComposeEmail() {
      this.shallShowEmailComposeModal = true;

    },

    async getData() {
      const resp = await ContractService.getContractsReport(
        this.$route.params.id,
        this.$store
      );
      if (resp.success) {
        this.dataReport = resp.dataDetail;
        this.dataContract = resp.dataContract;

        for (let index = 0; index < this.dataReport.length; index++) {
          this.options.xaxis.categories.push(
            this.dataReport[index].description
          );
          this.series[0].data.push(
            parseInt(this.dataReport[index].compliance_percentage)
          );
        }
        this.$refs.apexChart.updateOptions(this.options);
      }

      const respTable = await ContractService.getContract(
        this.$route.params.id,
        this.$store
      );
      if (respTable.success) {
        this.contractData = respTable.data;
        var arrayFormatTable = [];
        for (let i = 0; i < this.contractData.contract_detail.length; i++) {
          for (
            let j = 0;
            j < this.contractData.contract_detail[i].children.length;
            j++
          ) {
            var element = {
              numberParent: this.contractData.contract_detail[i].number,
              title:
                j == 0
                  ? this.contractData.contract_detail[i].description
                  : null,
              childrenLength:
                j == 0
                  ? this.contractData.contract_detail[i].children.length
                  : null,
              idParent: this.contractData.contract_detail[i].id,
              idChildren: this.contractData.contract_detail[i].children[j].id,
              number: this.contractData.contract_detail[i].children[j].number,
              subtitle:
                this.contractData.contract_detail[i].children[j].description,
              ref_legal:
                this.contractData.contract_detail[i].children[j].ref_legal,
              compliance:
                this.contractData.contract_detail[i].children[j].compliance,
              compliance_percentage:
                this.contractData.contract_detail[i].children[j]
                  .compliance_percentage,
              finding: this.contractData.contract_detail[i].children[j].finding,
            };
            arrayFormatTable.push(element);
          }
        }
        this.contractDetail = arrayFormatTable;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>