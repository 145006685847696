<template>
    <b-modal
      id="compose-mail"
      :visible="shallShowEmailComposeModal"
      title="Enviar Reporte"
      modal-class="modal-sticky"
      footer-class="d-flex justify-content-between"
      body-class="p-0"
      size="lg"
      no-fade
      @change="(val) => $emit('update:shall-show-email-compose-modal', val)"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          Enviar Reporte
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="MinusIcon"
            class="cursor-pointer"
            @click="$emit('update:shall-show-email-compose-modal', false)"
          />
          <feather-icon
            icon="Maximize2Icon"
            class="ml-1 cursor-pointer"
          />
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click="discardEmail"
          />
        </div>
      </template>
  
      <!-- Modal Footer -->
      <template #modal-footer>
        <!-- Footer: Left Content -->
        <div>
          <b-button 
            @click="sendEmailContract(); isSended ? $emit('update:shall-show-email-compose-modal', false) : incompleteEmail();"
            variant="primary"
          >
            Enviar
          </b-button>
        </div>
  
       
          
      </template>
  
      <!-- Modal: Body -->
      <b-form>
  
        <!-- Field: To -->
        <div class="compose-mail-form-field">
          <label
            for="email-to"
            class="form-label"
          >Para: </label>
          <v-select
            v-model="emailTo"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="name"
            class="flex-grow-1 email-to-selector"
            :close-on-select="true"
            :options="emailToOptions"
            input-id="email-to"
          >
  
            <template #option="{ avatar, name }">
              <b-avatar
                size="sm"
                :src="avatar"
              />
              <span class="ml-50"> {{ name }}</span>
            </template>
  
            <template #selected-option="{ avatar, name }">
              <b-avatar
                size="sm"
                class="border border-white"
                :src="avatar"
              />
              <span class="ml-50"> {{ name.split("<")[0] }}</span>
            </template>
          </v-select>
          
        </div>
  
        
  
        <!-- Field: Subject -->
        <div class="compose-mail-form-field">
          <label for="email-subject">Asunto: </label>
          <b-form-input
            id="email-subject"
            v-model="form.subject"
          />
        </div>
  
        <!-- Field: Message - Quill Editor -->
        <div class="message-editor">
          <quill-editor
            id="quil-content"
            v-model="bodyString"
            :options="editorOption"
          />
          <div
            id="quill-toolbar"
            class="d-flex border-bottom-0"
          >
            <!-- Add a bold button -->
            <button class="ql-bold" />
            <button class="ql-italic" />
            <button class="ql-underline" />
            <button class="ql-align" />
            <button class="ql-link" />
          </div>
        </div>
      </b-form>
  
    </b-modal>
  </template>
  
  <script>
  import {
    BDropdown, BDropdownItem, BForm, BFormInput, BAvatar, BDropdownDivider, BButton, BToast
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import UserService from "@/services/UserService";
  import ContractService from '@/services/ContractService';
  import { ref } from '@vue/composition-api'
  import { quillEditor } from 'vue-quill-editor'
  import vSelect from 'vue-select'
  
  export default {
    data(){
        return{
            isSended: false,
            arrayFilters: [],
            showEntrie: 10000,
            currentPage: 1,
            sort: "name",
            order: "asc",
            bodyString: 'Text Example',
            hasNoScript: false,
            emailTo: [],
            emailToOptions: [],
            stringEmails:"",
            form:{
                id:"",
                email: "",
                subject : "",
                content: ""
            },
            responsables:[]
        }
    },
    mounted() {
      this.getData();
  },
    methods: {
      async getData() {

        //GET USERS
        const url =
        `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=` +
        JSON.stringify(this.arrayFilters);

        await UserService.usuariosEmail(url, this.$store).then((response) => {
          this.emailToOptions = response.rows;
        });

        //GET CONTENT MESSAGE
        await ContractService.getBodyStringByPercentage(this.$route.params.id, this.$store).then((response) => {
          this.bodyString = response.data;
        });
        const resp = await ContractService.getContractsReport(
        this.$route.params.id,
        this.$store
        );
        const dataContract = resp.dataContract;
        this.form.subject = dataContract.company.business_name;

        await ContractService.getContractUserEmailbyId(this.$route.params.id, this.$store).then((response) =>{
          this.responsables = response.emailsToSend;
        });

        this.responsables.forEach(responsable => {
          this.emailTo.push(responsable)
        });
      },
      formatEmailsTo(){

        //FORMATING EMAILS TO
        let emailsTemp=""
        this.emailTo.forEach(email => {

          emailsTemp += email.split("<")[1]
        });
        this.stringEmails = emailsTemp.replaceAll(">", ",")
        this.stringEmails = this.stringEmails.substring(0, this.stringEmails.length-1);

      },
      incompleteEmail(){
        console.log("Falta llenar campos")
      },
      async sendEmailContract() {
        
        this.isSended = false;

        //CONTENT EMAILS
        this.form.content= this.bodyString;

        this.form.id =  this.$route.params.id;

        this.formatEmailsTo()
        this.form.email = this.stringEmails;
        if(this.form.content == ""){
          this.$bvToast.toast('Debe completar todos los campos', {
            title: 'Error',
            variant: 'danger',
            solid: true
          })
        }
        else{
          this.isSended = true;
          this.$bvToast.toast('Correo enviado correctamente', {
            title: 'Enviado',
            variant: 'success',
            solid: true
          })
          const resp = await ContractService.sendEmailContract(
            this.form,
            this.$store
            );
        }
      
    },
    },
    directives: {
      Ripple,
    },
    components: {
  
      // BSV
      BDropdown,
      BDropdownItem,
      BForm,
      BFormInput,
      BAvatar,
      BDropdownDivider,
      BButton,
      BToast,
  
      // 3rd Party
      quillEditor,
      vSelect,
    },
    model: {
      prop: 'shallShowEmailComposeModal',
      event: 'update:shall-show-email-compose-modal',
    },
    props: {
      shallShowEmailComposeModal: {
        type: Boolean,
        required: true,
      },
    },
    setup(_, { emit }) {
  
      const editorOption = {
        modules: {
          toolbar: '#quill-toolbar',
        },
        placeholder: 'Mensaje',
        theme: "snow",
      }
      
  
      const discardEmail = () => {
        emit('update:shall-show-email-compose-modal', false)
      }
  
      

      return {
        editorOption,
  
        // Email actions
        discardEmail,
      }
    },


  }
  </script>


  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import "@core/scss/base/pages/app-email.scss";
  @import '@core/scss/vue/libs/quill.scss';
  </style>
  
  <style lang="scss" scoped>
  form ::v-deep {
  
    // Mail To vue-select style
    .v-select {
      .vs__dropdown-toggle {
        border: 0;
        box-shadow: none;
      }
      .vs__open-indicator {
        display: none;
      }
    }
  
    // Quill Editor Style
    .quill-editor {
      .ql-container.ql-snow {
        border-bottom: 0 !important;
      }
    }
  }
  </style>
  <style lang="scss">
  .compose-mail-form-field{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.12rem 1rem;
    border-bottom: 1px solid rgb(206, 206, 206);
  
  .select2-container--open {
        box-shadow: none;
    }
    .select2-selection--multiple,
    .form-control {
    border: 0;
    background-color: transparent;
    &:focus {
        box-shadow: none !important;
        background-color: transparent;
    }
    }
    .select2-selection--multiple {
    min-height: 42px !important;
    .select2-selection__rendered li .select2-search__field {
        margin-top: 10px;
    }
    }
    .select2-selection__choice__remove:before {
    top: 40%;
    }
    label {
        margin-bottom: 0;
        font-size: 15px;
      }
    }
    .ql-editor {
      min-height: 250px;
    }
    .ql-container,
    .ql-toolbar {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid rgb(196, 196, 196);
      .ql-picker.ql-expanded .ql-picker-options {
        bottom: 100%;
        top: auto;
      }
    }
    .compose-footer-wrapper {
      padding: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  
</style>
  